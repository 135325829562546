.MuiAutocomplete-root, .MuiAutocomplete-popper  {
	width: 100% !important;
	max-width: 560px !important;
}

.MuiAutocomplete-listbox {
	list-style-type: circle !important;
	list-style-position: inside !important;
}

.MuiAutocomplete-popper  {
	margin-left: -5px !important;
}

.MuiAutocomplete-popper > div {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-top: 1px solid #4A7B85;
}

.MuiAutocomplete-popper > div > ul > li {
	background-color: #fff !important;
}

@media (max-width: 787px) {
  .MuiAutocomplete-root, .MuiAutocomplete-popper {
    width: 95vw !important;
	max-width: 95vw !important;
  }
}