/*Style the modal heading fonts */
.modal-title {
	font-family: "RobotoCondensed-Bold" !important;
}
/* Make the modal menu option title and chevron change on hover */
.modal-menuItem-flex:hover h5 {
	color: #4285f4 !important;
}
.modal-menuItem-flex:hover .modal-right-chevron {
	color: #4285f4 !important;
}

/*Flex boxes for modal menu options */
.modal-menuItem-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-content: stretch;
	align-items: center;
	cursor: pointer;
}
.modal-menuItem-flex-item:nth-child(1) {
	order: 0;
	flex: 0 1 auto;
	align-self: auto;
}
.modal-menuItem-flex-item:nth-child(2) {
	order: 0;
	flex: 0 1 auto;
	align-self: auto;
}

/*Style the modal menu item description*/
.modal-menuItem-description {
	font-size: 14px;
	color: #696969;
}

/*Style the model content box*/
.modal-content {
	padding: 5%;
	border-radius: 32px !important;
	background-color: #fcfcfc !important;
}
/* Style the modal header */
.modal-header {
	border-bottom: none !important;
}
/* Style the modal footer */
.modal-footer {
	border-top: none !important;
	justify-content: flex-start !important;
	justify-content: space-between !important;
}
/*Style the modal button */
.modalButton {
	color: #0a0a0a !important;
	background-color: #fcfcfc !important;
	border: none !important;
	font-family: "RobotoCondensed-Bold" !important;
	font-size: 18px !important;
}
/*Style the modal button */
.modalButton:hover {
	color: #4285f4 !important;
}

/*Style the icons */
/*Style the modal menu chevrons*/
.modal-right-chevron {
	width: 26px;
	height: 26px;
}
.modal-warning-triangle {
	width: 26px;
	height: 26px;
	color: #4285f4;
}

.topPage-venueSelect-profile-flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
}

.topPage-venueSelect-profile-flex-items:nth-child(1) {
	display: block;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	align-self: auto;
	order: 0;
}

.topPage-venueSelect-profile-flex-items:nth-child(2) {
	display: block;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	align-self: auto;
	order: 0;
}

b {
	font-family: "RobotoCondensed-Bold" !important;
}

[role="listbox"] {
	padding: 0 !important;
}
